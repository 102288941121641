const dev = {
  API_ENDPOINT_URL: 'https://auction.riolabz.com/v1/',
  // API_ENDPOINT_URL: 'https://auctionliveapi.riolabz.com/v1/',
  REFETCH_TIME: 60000, //For test server
  // REFETCH_TIME: 2000, //For live server
};

const prod = {
  API_ENDPOINT_URL: 'https://auction.riolabz.com/v1/',
  // API_ENDPOINT_URL: 'https://auctionliveapi.riolabz.com/v1/',
  REFETCH_TIME: 60000, //For test server/
  // REFETCH_TIME: 2000, //For live server
};

const test = {
  API_ENDPOINT_URL: 'https://auction.riolabz.com/v1/',
  // API_ENDPOINT_URL: 'https://auctionliveapi.riolabz.com/v1/',
  REFETCH_TIME: 60000, //For test server
  // REFETCH_TIME: 2000, //For live server
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return dev;
    case 'production':
      return prod;
    case 'test':
      return test;
    default:
      break;
  }
};

export const env = getEnv();
