import { Link } from 'react-router-dom';

import classes from './NavbarLogo.module.css';

const NavbarLogo = ({ onClick }) => {
  return (
    <Link to="/" onClick={onClick}>
      <img
        src="/img/header-logo.svg"
        alt="Logo"
        loading="lazy"
        className={classes.navbarLogo}
      />
    </Link>
  );
};

export default NavbarLogo;


