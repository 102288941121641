import { useCallback, useEffect, lazy, Suspense, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { userActions } from 'store-redux/user-slice';

import { APP_PREFIX_PATH } from 'configs/AppConfig';

import Layout from 'components/layout/Layout';
import DispatchSignInModal from 'hooks/DispatchSignInModal';
import LoadingSpinner from 'components/UI/loading-spinner/LoadingSpinner';

const Home = lazy(() => import(`pages/Home`));


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      retryDelay: 2000
    }
  }
});

const App = () => {
  // access the modalSlice State in the Store Reducer
  const { modalIsVisible, modalType } = useSelector((state) => state.modal);
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const [checkForAuthorization, setCheckForAuthorization] = useState(false);


  return (
    <QueryClientProvider client={queryClient}>
      <Layout
        notScrollable={modalIsVisible}
      >
        <Routes>
          <Route
            path={`${APP_PREFIX_PATH}`}
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Home />
              </Suspense>
            }
          />
        </Routes>
      </Layout>
    </QueryClientProvider>
  );
};

export default App;
