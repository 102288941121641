import { useDispatch, useSelector } from "react-redux";

import { modalActions } from "store-redux/modal-slice";
import { NavbarLinks } from "helpers/constants";

import HumburgerButton from "components/UI/button/HumburgerButton";
import Button from "components/UI/button/Button";

import NavbarList from "./navbar-list/NavbarList";
import NavbarLogo from "./navbar-logo/NavbarLogo";


import classes from "./Navbar.module.css";
import { useState } from "react";
import { Modal } from "antd";
import { Link, Element, scroller } from "react-scroll";

// Navbar component => the Parent Component for the Navbar elements
// all the navbar related components are child for this component
let emailValue = "";
const Navbar = () => {
  const dispatch = useDispatch();
  const { modalIsVisible, modalType } = useSelector((state) => state.modal);
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const setSigninModalHandler = () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: "signin",
      })
    );
  };

  const setSignupDetailsModalHandler = (value) => {
    emailValue = value;
    dispatch(
      modalActions.changeModalComponent({
        modalType: "signupDetails",
      })
    );
  };

  const setSideNavbarIsOpenHandler = () => {
    setMenuOpen(!menuOpen);
    setShowDropdown(false);
    if (modalType === "sidenav") {
      dispatch(modalActions.toggleModal());
    } else {
      dispatch(
        modalActions.changeModalComponent({
          modalType: "sidenav",
        })
      );
    }
  };

  const closeModalHandler = () => {
    if (modalIsVisible) {
      dispatch(modalActions.toggleModal());
    }
  };

  const toggleModalHandler = () => {
    // toggle the visability of the Modal
    dispatch(modalActions.toggleModal());
  };

  const handleCancel = () => {
    setMenuOpen(false);
  };

  const productsOpen = () => {
    setShowDropdown(!showDropdown);
  };

  const menuItemOpen = () => {
    setShowDropdown(false);
    setMenuOpen(false);
    document.body.style.overflow = 'auto';
    
  };

  const openMenuhandler = () => {
    setMenuOpen(true);
    document.body.style.overflow = 'hidden';
  }

  const closeMenuHandler = () => {
    setMenuOpen(false);
    setShowDropdown(false);
    document.body.style.overflow = 'auto';
  }

  return (
    <>
      <div className={`${classes.mainNavbar}`}>
        <NavbarLogo onClick={closeModalHandler} />
        <div className={classes.navbarList}>
          <NavbarList navList={NavbarLinks} />
          {/* <InputForm
          name="search cars"
          placeholder="Search for cars (ex.  BMW, Audi)"
          onSubmit={() => {}}
        /> */}
        </div>

        {/* <div className={classes.navBarContact}>
          <a
            href="tel:8891516767"
            style={{ display: "flex", gap: "8px", alignItems: "center" }}
          >
            <img src="/img/navbar-contact.svg" />
            <p>+91 8891516767</p>
          </a>
        </div> */}
        {!menuOpen ? (
          <img
            onClick={openMenuhandler}
            className={classes.burgerIcon}
            src="/img/burger-icon.svg"
          />
        ) : (
          <img
            onClick={closeMenuHandler}
            className={classes.closeIcon}
            src="/img/close-icon.svg"
          />
        )}
      </div>
      {menuOpen && (
        <div className={classes.burgerMenuOuter}>
          <div className={classes.menuList}>
            <li className={classes.productList} onClick={productsOpen}>
              Products
              <img src="/img/arrow-down.svg" />
            </li>
            {showDropdown && (
              <div className={classes.dropDownCont}>
                <Link to="auctionSlider" offset={-100}>
                  <li onClick={menuItemOpen}>
                    Auction
                    <img src="/img/auction-logo.svg" />
                  </li>
                </Link>
                <Link to="ymsSlider" offset={-100}>
                  <li onClick={menuItemOpen}>
                    YMS
                    <img src="/img/yms-logo.svg" />
                  </li>
                </Link>
                <Link to="rmsSlider" offset={-100}>
                  <li onClick={menuItemOpen}>
                    RMS
                    <img src="/img/rms-logo.svg" />
                  </li>
                </Link>
                <Link to="evaluationSlider" offset={-100}>
                  <li onClick={menuItemOpen}>
                    Evaluation
                    <img src="/img/evaluation-logo.svg" />
                  </li>
                </Link>
                {/* Add more items as needed */}
              </div>
            )}
            <Link to="aboutUs" offset={-60}>
              <li onClick={menuItemOpen}>Abouts us</li>
            </Link>
            {/* <Link to="testimonials" offset={-60}>
              <li onClick={menuItemOpen}>Testimonials</li>
            </Link>
            <Link to="whats_new" offset={-60}>
              <li onClick={menuItemOpen}>What's New</li>
            </Link> */}
            <Link to="contact_us" offset={-60}>
              <li onClick={menuItemOpen}>Contact us</li>
            </Link>
            {/* <li>Terms & Conditions</li> */}
          </div>
          <div className={classes.contactSection}>
            <li>
            <a
            href="tel:8891516767"
            style={{ display: "flex", gap: "8px", alignItems: "center" }}
          >
              <img src="/img/footer-contact-black.svg" />
            +91 8891516767
            </a>
            </li>
            <li>
            <a style={{display:"flex", gap:"8px", alignItems: "center"}} href="mailto:contact@vehiclemall.in">
              <img src="/img/footer-mail-black.svg" />
            contact@vehiclemall.in
            </a>
            </li>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
