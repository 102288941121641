import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { modalActions } from "store-redux/modal-slice";
import { userActions } from "store-redux/user-slice";

import { NavbarLinks, NavbarSideLinksPrivate } from "helpers/constants";

import NavbarItem from "./navbar-item/NavbarItem";

import classes from "./NavbarList.module.css";
import { useState } from "react";
import { Link, Element, scroller } from "react-scroll";

const NavbarList = ({ navigationList = null, className, onClose, isSide }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");

  const setSigninModalHandler = () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: "signin",
      })
    );
  };

  const onClickSignoutHandler = () => {
    dispatch(userActions.logout());
    dispatch(modalActions.toggleModal());
    navigate("/");
  };

  let navList = null;
  if (navigationList) {
    navList = navigationList.map((link, index) => (
      <NavbarItem
        navItem
        onClick={onClose}
        key={link.key ? link.key : link.to + index}
        pageLink={link.to}
      >
        {link.title}
      </NavbarItem>
    ));
  } else if (!isSide || !isLoggedIn) {
    navList = NavbarLinks.map((link, index) => (
      <NavbarItem
        navItem
        onClick={onClose}
        key={link.key ? link.key : link.to + index}
        pageLink={link.to}
      >
        {link.title}
      </NavbarItem>
    ));
  } else {
    navList = NavbarSideLinksPrivate.map((link, index) => (
      <NavbarItem
        navItem
        onClick={onClose}
        key={link.key ? link.key : link.to + index}
        pageLink={link.to}
      >
        {link.title}
      </NavbarItem>
    ));
  }

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
    setActiveMenu("products");
  };

  const toggleMouseLeave = () => {
    setActiveMenu("");
    setShowDropdown(!showDropdown);
  };

  const MouseEnterAction = (data) => {
    setActiveMenu(data);
  };

  const MouseLeaveAction = () => {
    setActiveMenu("");
  };

  return (
    <ul className={`${classes.navbarList} ${className}`}>
      <>
        <li
          className={classes.navbarLists}
          style={{ position: "relative" }}
          onMouseEnter={toggleDropdown}
          onMouseLeave={toggleMouseLeave}
        >
          <div
              className={`${
                activeMenu == "products"
                  ? classes.navBarLined
                  : classes.hideActiveMenu
              }`}
            ></div>
          <span style={{ position: "relative", top: "11px", height: "40px" }}>
            Products
          </span>
          <img className={classes.arrowIcon} src="/img/arrow-down.svg" />
          {showDropdown && (
            <div className={classes.dropDownContent}>
              <Link to="auctionSlider" offset={-100}>
                <li>
                  <h4>Auction</h4>
                  <img src="/img/auction-logo.svg" />
                </li>
              </Link>
              <Link to="ymsSlider" offset={-100}>
                <li>
                  <h4>YMS</h4>
                  <img src="/img/yms-logo.svg" />
                </li>
              </Link>
              <Link to="rmsSlider" offset={-100}>
                <li>
                  <h4>RMS</h4>
                  <img src="/img/rms-logo.svg" />
                </li>
              </Link>
              <Link to="evaluationSlider" offset={-100}>
                <li>
                  <h4>Evaluation</h4>
                  <img src="/img/evaluation-logo.svg" />
                </li>
              </Link>
              {/* Add more items as needed */}
            </div>
          )}
        </li>
        <Link to="aboutUs" offset={-60}>
          <li
            onMouseEnter={() => MouseEnterAction("about_us")}
            onMouseLeave={MouseLeaveAction}
            className={classes.navbarLists}
          >
            <div
              className={`${
                activeMenu == "about_us"
                  ? classes.navBarLine
                  : classes.hideActiveMenu
              }`}
            ></div>
            <span>About us</span>
          </li>
        </Link>
        {/* <Link to="testimonials" offset={-60}>
          <li
            onMouseEnter={() => MouseEnterAction("testimonials")}
            onMouseLeave={MouseLeaveAction}
            className={classes.navbarLists}
          >
            <div
              className={`${
                activeMenu == "testimonials"
                  ? classes.navBarLine
                  : classes.hideActiveMenu
              }`}
            ></div>
            <span>Testimonials</span>
          </li>
        </Link>
        <Link to="whats_new" offset={-60}>
          <li
            className={classes.navbarLists}
            onMouseEnter={() => MouseEnterAction("whats_new")}
            onMouseLeave={MouseLeaveAction}
          >
            <div
              className={`${
                activeMenu == "whats_new"
                  ? classes.navBarLine
                  : classes.hideActiveMenu
              }`}
            ></div>
            <span>What's New</span>
          </li>
        </Link> */}
        <Link to="contact_us" offset={-60}>
          <li
            className={classes.navbarLists}
            onMouseEnter={() => MouseEnterAction("contact_us")}
            onMouseLeave={MouseLeaveAction}
          >
            <div
              className={`${
                activeMenu == "contact_us"
                  ? classes.navBarLine
                  : classes.hideActiveMenu
              }`}
            ></div>
            <span>Contact us</span>
          </li>
        </Link>
        <li
          className={classes.navbarLists}
          onMouseEnter={() => MouseEnterAction("terms_conditions")}
          onMouseLeave={MouseLeaveAction}
        >
          <div
            className={`${
              activeMenu == "terms_conditions"
                ? classes.navBarLine
                : classes.hideActiveMenu
            }`}
          ></div>
          {/* <span>Terms & Conditions</span> */}
        </li>
      </>
    </ul>
  );
};

export default NavbarList;
